export const route = {
    webUrls: {
        whatsApp: 'https://api.whatsapp.com/send?text=',
    },
    'login': '/app/login',
    'chooseAccountType': '/app/account/select-type',
    'createAccount': '/app/account/sign-up',
    'welcome': 'welcome',
    'registrationOtp': '/app/account/verify-otp',
    'forgotPassword': '/app/account/recover/forgot-password',
    'updatePassword': '/app/account/recover/create-new-pin',
    shipper: {
        indent: '/app/shipper/indent',
        users: '/app/shipper/users',
        createUser: '/app/shipper/users/create',
        findTruck: '/app/shipper/findTruck',
        profile: '/app/shipper/profile',
        addSource: '/app/shipper/indent/create/source',
        addDestination: '/app/shipper/indent/create/destination',
        createIndent: '/app/shipper/indent/create',
        addTruckType: '/app/shipper/indent/create/trucktype',
        addMaterialType: '/app/shipper/indent/create/materialtype',
        logout:'/app/shipper/logout'
    },
    carrier: {
        users: '/app/carrier/users',
        indent: '/app/carrier/indent',
        trips: '/app/carrier/trips',
        active: '/app/carrier/trips/active',
        delivered: '/app/carrier/trips/delivered',
        balance: '/app/carrier/trips/balance',
        paid: '/app/carrier/trips/paid',
        account: '/app/carrier/account',
        createUser: '/app/carrier/users/create',
        truck: '/app/carrier/truck',
        findLoad: '/app/carrier/indent',
        profile: '/app/carrier/profile',
        addTruck: '/app/carrier/truck/create',
        selectTruckType: '/app/carrier/truck/create/truckType',
        indentTruckFilter: '/app/carrier/indent/filter',
        logout:'/app/carrier/logout'
    },
    employee: {
        login: '/app/employee/login',
        indent: '/app/employee/indent',
        carrier: '/app/employee/carrier',
        createCarrier: '/app/employee/carrier/create',
        shipper: '/app/employee/shipper',
        leadShippers: '/app/employee/shipper/lead',
        leadCarriers: '/app/employee/carrier/lead',
        createShipper: '/app/employee/shipper/create',
        createIndent: '/app/employee/indent/create',
        selectCustomer: '/app/employee/indent/customer',
        indentTruckFilter: '/app/employee/indent/filter',
        indentStatusFilter: '/app/employee/indent/status',
        selectShipper: '/app/employee/indent/create/shipper',
        selectSource: '/app/employee/indent/create/source',
        selectDestination: '/app/employee/indent/create/destination',
        selectTruckType: '/app/employee/indent/create/trucktype',
        selectMaterialType: '/app/employee/indent/create/materialtype',
        selectEmployeeContact: '/app/employee/indent/create/employeeContact',
        selectShipperContact: '/app/employee/indent/create/shipperContact',
        carrierInterest: '/app/employee/indent/create/carrier',
        indentListShipperFilter : '/app/employee/indent/selectshipper',
        indentListEmployeeFilter : '/app/employee/indent/selectEmployee',
        indentListPeriodFilter : '/app/employee/indent/period',
        users: '/app/employee/users',
        addEmployee : '/app/employee/users/create',
        createShipperUser: '/app/employee/shipper/user/create',
        createCarrierUser: '/app/employee/carrier/user/create',
        carrierManagedBy: '/managed-by',
        carrierCityPreference: '/city-preference',
        carrierTruckTypePreference: '/truck-type-preference',
        trips: '/app/employee/trips',
        confirmed: '/app/employee/trips/confirmed',
        loading: '/app/employee/trips/loading',
        intransit: '/app/employee/trips/intransit',
        unloading: '/app/employee/trips/unloading',
        delivered: '/app/employee/trips/delivered',
        invoiced: '/app/employee/trips/invoiced',
        closed: '/app/employee/trips/closed',
        payments: '/app/employee/payments',
        shipperAdvance: '/app/employee/payments/shipper-advance',
        shipperBalance: '/app/employee/payments/shipper-balance',
        carrierAdvance: '/app/employee/payments/carrier-advance',
        carrierBalance: '/app/employee/payments/carrier-balance',
        report: '/app/employee/report',
        profile:'/app/employee/profile',
        logout:'/app/employee/profile/logout',
        trucks: '/app/employee/trucks',
    },
    resources: {
      shippers: '/app/resources/shippers',
      shipperContact: '/app/resources/shipper-contact',
      employees: '/app/resources/employees',
      carriers: '/app/resources/carriers',
      trucks: '/app/resources/trucks',
      truckType: '/app/resources/truckType',
      truckTypeFilter: '/app/resources/trucktype-filter',
      drivers: '/app/resources/drivers',
      materials: '/app/resources/materials',
      cities: '/app/resources/cities',
      addTruck: '/app/resources/addTruck',
      indentStatus: '/app/resources/indent-status',
      roles: '/app/resources/roles',
      companyComment: '/app/resources/company-comment',
      truckComment: '/app/resources/truck-comment',
      indentComment: '/app/resources/indent-comment',
      tripComment: '/app/resources/trip-comment'
    },
    screen: {
        REGISTRATION: 'REGISTRATION',
        LOGIN: 'LOGIN',
        UPDATE_PASSWORD: 'UPDATE_PASSWORD'
    }
}